.production-button {
	display: flex;
	justify-content: center;
	margin-top: 50px;
	margin-bottom: 90px;
}

.production-button a {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 403px;
	height: 46px;
	background-color: #ffa000;
	border-radius: 23px;
	border: 1px solid #ffa000;
	transition: background-color .3s;
}

.production-button a:hover {
	background-color: white;
}

.production-button a span {
	color: white;
	font-family: 'Roboto', sans-serif;
	font-size: 11px;
	font-weight: 900;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 2px;
	transition: color .3s;
}

.production-button a:hover span {
	color: #ffa000;
}

.services-info .production-button {
	margin-bottom: 50px;
}

@media (width <= 991px) {
	.production-button {
		margin-bottom: 70px;
	}
}

@media (width <= 479px) {
	.production-button a {
		width: 300px;
		height: 46px;
	}

	.production-button a span {
		font-size: 12px;
		letter-spacing: normal;
	}
}