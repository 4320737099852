.services-photo__wrapper {
		padding-bottom: 40px;
}

.services-photo__wrapper h2 {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 26px;
	font-weight: 600;
	margin-bottom: 25px;
}

.services-photo {
	padding-top: 20px;
}

.caption.caption-visible {
	display: flex;
	width: 100%;
	height: 100%;
	text-align: center;
	line-height: 50px;
	font-family: "Myriad Pro", sans-serif;
	font-size: 14px;
	font-style: italic;
}

.caption.caption-visible::before,
.caption.caption-visible::after {
	position: absolute;
	content: "";
	width: 47px;
	height: 47px;
	top: calc(50% - 24px);
	left: calc(50% - 24px);
	background-repeat: no-repeat;
	background-position: center;
}

.caption.caption-visible::before {
	border: 2px solid white;
	border-radius: 50%;
}

.caption.caption-visible::after {
	background-size: 21px 21px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAMAAACeyVWkAAAARVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////9SnXPCAAAAFnRSTlMAh3fXeNsnHXAV8OTf0EwaEEgiDO84HcrY1gAAAHdJREFUGNOFz0kSgzAMRNEGxfKAsSEkuv9Rg80mXbiKv3wbqfFfna52UPvHWhM4lZF6N9B3trvqctLqWLeOURMpgplEoLAiON9vgjvw3AYu9j+XwDoXwGcz5lfS2mbmg9ScnCgerC2puOsaMVDRkVoqrN/5inb8AHbfBzEfBIbOAAAAAElFTkSuQmCC');
}

@media (width >= 1200px) {
	.caption.caption-visible::after {
		animation: pulse 1s infinite;
	}
}

@keyframes pulse {
	from {transform: scale(1);}
	50% {transform: scale(1.1);}
	to {transform: scale(1);}
}
