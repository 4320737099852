.about__wrapper {
	display: flex;
	justify-content: space-between;
	margin-top: 80px;
	margin-bottom: 90px;
}

.about__text {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	flex-basis: calc(50% - 50px);
	flex-grow: 1;
}

.about__text h2 {
	margin-top: 0;
	margin-bottom: 0;
	color: #444;
	font-family: "Myriad Pro", sans-serif;
	font-size: 32px;
	line-height: 38px;
	text-transform: uppercase;
}

.about__text p {
	color: #444;
	font-family: "Myriad Pro", sans-serif;
	font-size: 14px;
	line-height: 31px;
	padding-bottom: 40px;
	margin-bottom: 0;
}

.price-download {
	display: inline-block;
	height: 46px;
	letter-spacing: 1px;
	width: 235px;
	color: #ffa000;
	font-family: 'Roboto', sans-serif;
	font-size: 11px;
	font-weight: 900;
	line-height: 46px;
	text-transform: uppercase;
	background-color: white;
	border: 1px solid #ffa000;
	border-radius: 23px;
	text-align: center;
	vertical-align: center;
	text-decoration: none;
	transition: background-color 0.3s, color .3s;
}

.price-download:hover {
	color: white;
	background-color: #ffa000;
}

.about__img {
	flex-basis: 50%;
	margin-left: 50px;
	flex-grow: 1;
}

.about__img img{
	width: 100%;
	-o-object-fit: cover;
	margin-top: 10px;
	border: 9px solid #fafafa;
	box-shadow: 2.5px 4.3px 5px rgba(220, 220, 220, 0.75);
}

.about__img .price-download {
	display: none;
}

.strip {
	width: 85px;
	height: 2px;
	background-color: #ffa000;
	margin-top: 30px;
	margin-bottom: 30px;
}

@media (max-width: 991px) {
	.about__wrapper {
		flex-wrap: wrap;
		align-items: flex-start;
		position: relative;
		padding-bottom: 80px;
		margin-bottom: 0;
		width: 100%;
	}

	.about__text .price-download {
		display: none;
	}

	.about__img .price-download {
		display: block;
		margin-top: 50px;
		margin-right: auto;
		margin-left: auto;
	}

	.about__text {
		flex-basis: 100%;
		width: 100%;
	}
	
	.about__text p {
		padding-bottom: 0;
		margin-bottom: 25px;
		font-size: 16px;
		line-height: 34px;
	}

	.about__img {
		margin-top: 0;
		margin-left: 0;
		width: 100%;
		flex-basis: 100%;
	}
}

@media (max-width: 767px) {
	.about__wrapper {
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 70px;
	}
}

@media (max-width: 480px) {
	.about__wrapper {
		margin-top: 70px;
		padding-left: 10px;
		padding-right: 10px;
	}
}