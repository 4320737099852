.carousel-control {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 10%;
}

.carousel-control.right {
	background-position: center;
	background-size: 51px 201px;
	background-repeat: no-repeat;
	left: auto;
	right: 0;
	background-image: url(../img/arrow-slider.svg);
}

.carousel-control.left {
	background-position: center;
	background-size: 51px 201px;
	background-repeat: no-repeat;
	left: 0;
	right: auto;
	background-image: url(../img/arrow-slider.svg);
}

.left.carousel-control {
	transform: rotate(180deg);
	left: 5px;
}

.right.carousel-control {
	right: 5px;
	z-index: 10;
}

@media (width >= 1200px) {
	.left.carousel-control {
	left: 0;
}

.right.carousel-control {
	right: 0;
}
}

@media (max-width: 479px) {
	.carousel .carousel-control {
		background-image: none;
	}
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 991px) {
	.carousel-control.right,
	.carousel-control.left {
	background-size: 38px 150px;
}
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 767px) {
	.carousel-control.right,
	.carousel-control.left {
	background-image: none;
}
}

@media (max-width: 992px) {
	_:-ms-lang(x), _:-webkit-full-screen, .carousel-control.right {
		background-size: 38px 150px;
	}
}

@media (max-width: 992px) {
	_:-ms-lang(x), _:-webkit-full-screen, .carousel-control.left {
		background-size: 38px 150px;
	}
}

@media (max-width: 767px) {
	_:-ms-lang(x), _:-webkit-full-screen, .carousel-control.left {
		background-image: none;
	}
}

@media (max-width: 767px) {
	_:-ms-lang(x), _:-webkit-full-screen, .carousel-control.right {
		background-image: none;
	}
}