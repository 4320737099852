.contacts__details {
	flex-basis: calc(50% - 40px);
	padding-top: 35px;
	padding-left: 40px;
}

@media (width <= 991px) {
	.contacts__details {
		flex-basis: 100%;
	}	
}

@media (width <= 767px) {
	.contacts__details {
		padding-left: 40px;
		padding-right: 40px;
	}

	.contacts__details h2, 
	.contacts__detail-item {
		text-align: center;
	}
}

.contacts__detail-item {
	margin-bottom: 25px;
}

.contacts__details h2 {
	margin-top: 0;
	margin-bottom: 5px;
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 26px;
	font-weight: 600;
	line-height: 38px;
	letter-spacing: 2px;
}

.contacts__details p {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 16px;
	line-height: 30px;
	font-weight: 400;
	letter-spacing: 1px;
	margin-bottom: 0;
}

.contacts__detail-item p span {
	font-weight: 600;
}

.contacts__details a {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 16px;
	line-height: 30px;
	font-weight: 400;
	@apply --transitionOpacityStart;	
}

.contacts__details a:hover {
	@apply --transitionOpacityEnd;
}

.contacts__details .contacts__email {
	color: #ffa000;
	font-weight: 600;
}