.sidebar {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
}

@media (width >= 768px) {
	.sidebar {
		position: static;
		flex-basis: 300px;
		width: 300px;
		display: block;
	}	
}

@media (width >= 1200px) {
	.sidebar {
		flex-basis: 280px;
		width: 280px;
		display: block;
	}	
}



@media (width >= 768px) {
	.sidebar__category .sidebar__heading {
		display: none;
	}
}

.sidebar__category {
	position: relative;
	background-color: #fafafa;
	box-shadow: 0 0 5px rgba(220, 220, 220, 0.75);
	z-index: 1;
	overflow: auto;
	padding-top: 25px;
}

/* .sidebar__category .navbar-nav {
	list-style: none;
	float: none;
	padding-left: 0;
	width: 100%;
	display: block;
} */

.sidebar__category .navbar-nav > li {
	width: 80%;
	margin-left: 20px;
}

.sidebar__category .navbar-nav > li > a {
	border-bottom: 2px solid #cecece;
}

.sidebar__category .navbar-nav > li:last-child a {
	border-bottom: none;
}

.sidebar__category .navbar-nav > li.dropdown.open > a{
	border-bottom: 2px solid #ffa000;
}

.sidebar__category .navbar-nav > li.dropdown.open,
.sidebar__category .navbar-nav > li:last-child {
	border-bottom: none;
	
}

.sidebar__category .navbar-nav > li > a {
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 0;
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 18px;
	font-weight: 600;
	line-height: 38px;
	text-transform: none;
	background-color: transparent;
	transition: color .3s;
	letter-spacing: 1px;
}

@media (width >= 992px) {
	.sidebar__category .navbar-nav > li.active > a,
	.sidebar__category .navbar-nav > li.dropdown.open > a,
	.sidebar__category .navbar-nav > li:hover > a {
		color: #222;
	}
}

.sidebar__category .navbar-nav .dropdown-menu {
	position: static;
	width: 100%;
	z-index: 1;
	border: none;
	box-shadow: none;
	background-color: transparent;
	padding-top: 0;
	padding-bottom: 0;
	margin-top: 10px;
	margin-bottom: 10px;
}

.sidebar__category .dropdown-menu li {
	width: 100%;
}

.sidebar__category .dropdown-menu a {
	color: #222;
	width: 100%;
	padding: 10px 15px;
	text-transform: none;
	white-space: normal;
	background-color: #fafafa;
	transition: color .3s;
	font-family: "Myriad Pro", sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 38px;
	letter-spacing: 1px;
}

.sidebar__category .dropdown-menu li.active a,
.sidebar__category .dropdown-menu li:hover a {
	color: #ffa000;
}

.sidebar__category .dropdown-menu > .active > a, 
.sidebar__category .dropdown-menu > .active > a:hover, 
.sidebar__category .dropdown-menu > .active > a:focus {
	background-color: #fafafa;
}

.sidebar__category .nav .open > a, 
.sidebar__category .nav .open > a:hover, 
.sidebar__category .nav .open > a:focus {
   background-color: #fafafa;
   border: none;
}

.sidebar__category .dropdown-menu > li > a:hover, 
.sidebar__category .dropdown-menu > li > a:focus {
    color: #ffa000;
    text-decoration: none;
    background-color: #fafafa;
}

.sidebar .download-price {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 100%;
	height: 60px;
	background-color: #ffa000;
	border: 1px solid #ffa000;
	text-decoration: none;
	transition: background-color 0.3s;
}

.sidebar .download-price:hover {
	background-color: transparent;
	border: 1px solid #ffa000;
}

.sidebar .download-price span {
	color: white;
	font-family: 'Roboto', sans-serif;
	font-size: 12px;
	font-weight: 900;
	line-height: 12px;
	text-transform: uppercase;
	letter-spacing: 1px;
	transition: color 0.3s;
}

.sidebar .download-price:hover span {
	color: #ffa000;
}

@media (width <= 991px) {
	.sidebar {
		display: none;
	}
}

