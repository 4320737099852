.services-info {
	margin-bottom: 50px;
}

.services-text p {
	color: #444;
	font-family: "Myriad Pro", sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 31px;
}

.services-text p:last-child {
	margin-bottom: 0;
}

.services-text ol,
.services-text ul {
	list-style: none;
}


.services-text ol {
	counter-reset: my-counter;
}

.services-text li {
	position: relative;
	color: #444;
	font-family: "Myriad Pro", sans-serif;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 15px;
}

.services-text ul li::before {
	position: absolute;
	top: 5px;
	left: -26px;
	display: block;
	width: 6px;
	height: 10px;
	background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAKBAMAAABlIDIAAAAAElBMVEUAAAD/oQD/oQD/oQD/oQD/oQCmJd1/AAAABnRSTlMA3x+oPAdXhUhUAAAAIElEQVQI12NgZWBgcFRgYGAWAjIMwQwQBrGBFFgOpAYAI34Bw0HsqRYAAAAASUVORK5CYII=');
	content: "";
}

.services-text ol li::before {
	position: absolute;
	top: -3px;
	left: -40px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	background-color: #ffa000;
	content: counter(my-counter);
	counter-increment: my-counter;
	font-family: "Myriad Pro", sans-serif;
	color: white;
	font-size: 14px;
	line-height: normal;
}

.services-text h1 {
	color: #ffa000;
	font-family: "Myriad Pro", sans-serif;
	font-size: 32px;
	font-weight: 600;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.services-text h2 {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 22px;
	font-weight: 600;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.services-text h3 {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 20px;
	font-weight: 600;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.services-text h4 {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 18px;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 1px;
}

@media (width <= 479px) {
	.services-text h1,
	.services-text h2,
	.services-text h3,
	.services-text h4 {
		text-align: center;
	}
}