@media (width >= 480px) {}
@media (width >= 768px) {}
@media (width >= 992px) {}
@media (width >= 1200px) {}
@media (width <= 1199px) {}
@media (width <= 991px) {}
@media (width <= 767px) {}
@media (width <= 641px) {}
@media (width <= 479px) {}

body {
	min-width: 320px;
}

a {
	outline-color: transparent!important;
}

a:hover,
a:focus {
	text-decoration: none;
}

html,
body {
	height: 100%;
}

.mail {
	display: none;
}