.question {
	background-color: #ffa000;
	padding-top: 50px;
	padding-bottom: 50px;
}

.question__wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.question__wrapper p {
	color: white;
	font-family: "Myriad Pro", sans-serif;
	font-size: 32px;
	line-height: 38px;
	padding-right: 55px;
	text-align: center;
	vertical-align: center;
}

.question__wrapper a {
	height: 46px;
	letter-spacing: 1px;
	width: 235px;
	color: #222;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 46px;
	text-transform: uppercase;
	background-color: white;
	border-radius: 23px;
	box-shadow: 0 0 6px rgba(220, 220, 220, 0.75);
	text-align: center;
	text-decoration: none;
	border: 1px solid white;
	transition: background-color 0.3s, color .3s;
}

.question__wrapper a:hover {
	color: white;
	border: 1px solid white;
	box-shadow: 0 0 6px rgba(220, 220, 220, 0.75);
	background-color: transparent;
}

@media (max-width: 767px) {
	.question {
		padding-bottom: 60px;
	}

	.question__wrapper {
		flex-direction: column;	
	}

	.question__wrapper p {
		padding-bottom: 30px;
		padding-right: 0;
	}
}

@media (max-width: 479px) {
	.question {
		padding-top: 35px;
		padding-bottom: 40px;
	}

	.question__wrapper p {
		font-size: 29px;
		padding-bottom: 25px;
	}
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 479px) {
	.question__wrapper p {
		font-size: 27px;
	}
}