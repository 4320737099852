.brand__title {
	margin-top: 60px;
	margin-bottom: 10px;
}

.brand__title h1 {
	text-align: center;
	padding: 0;
	color: #ffa000;
	font-family: "Myriad Pro", sans-serif;
	font-size: 32px;
	font-weight: 600;
	line-height: 1;
	text-transform: uppercase;
	margin: 0;
	letter-spacing: 2px;
}

.brand__title h2 {
	text-align: center;
	padding: 0;
	color: #ffa000;
	font-family: "Myriad Pro", sans-serif;
	font-size: 32px;
	font-weight: 600;
	line-height: 1;
	text-transform: uppercase;
	margin: 0;
	letter-spacing: 2px;
}

@media (width <= 479px) {
	.brand__title h2 {
		font-size: 24px;
	}
}