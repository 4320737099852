.site-footer-info {
	background-color: #222;
}

.site-footer-info__wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: white;
	padding-top: 35px;
	padding-bottom: 35px;
}

.site-footer__logo {
	display: flex;
	flex-direction: column;
}

a.site-footer__logo {
	text-decoration: none;
	will-change: opacity;
	opacity: 1;
	transition: opacity .3s;
}

.site-footer__logo:hover {
	opacity: 0.7;
}

.site-footer__logo span {
	display: inline-block;
	color: #ffa000;
	font-family: 'Roboto', sans-serif;
	font-size: 24px;
	font-weight: 500;
	padding-bottom: 5px;
}

.site-footer__logo p {
	color: white;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 300;
	line-height: 24px;
	margin-bottom: 0;
}

/* .site-footer__logo img {
	display: none;
} */

.site-footer__contact {
	display: flex;
	justify-content: center;
	align-items: center;
}

.site-footer__contact--item {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-right: 40px;
}

.site-footer__contact--item:last-child {
	padding-right: 0;
}

.site-footer__contact--item svg {
	min-width: 33px;
}

.site-footer__contact--text {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-left: 20px;
	color: white;
	font-family: "Myriad Pro", sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 30px;
}

.site-footer__icon {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.site-footer__icon a {
	display: inline-block;
	margin: 20px 15px 15px;
}

.site-footer__copy-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 15px;
	padding-bottom: 15px;
}

.site-footer__copyright {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 14px;
	line-height: 26px;
	margin-bottom: 0;
}

.site-footer__copyright p {
	margin-bottom: 0;
}

.site-footer__zwebra-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	/* padding-bottom: 10px; */
	/* flex-basis: 200px; */
	min-width: 200px;
	will-change: opacity;
	opacity: 1;
	transition: opacity .3s;
}

.site-footer__zwebra-logo:hover {
	opacity: 0.7;
}

a.site-footer__zwebra-logo {
	text-decoration: none;
}

.zwebra-logo__text {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	color: #a6a4a4;
	font-size: 12px;
	line-height: 14px;
	margin-right: 10px;
	padding-top: 2px;
}

.zwebra-logo__text span:nth-child(1) {
	text-transform: uppercase;
}

.zwebra-logo__text span:nth-child(2) {
	font-weight: bold;
}

.zwebra-logo__img {
	background-image: url("../img/zwebra-logo.png");
	background-repeat: no-repeat;
	background-size: 81px 24px;
	width: 81px;
	height: 24px;
}

@media (width <= 1199px) {
	a.site-footer__logo {
		margin-right: 20px;
	}
}

@media (width <= 991px) {

	.site-footer-info__wrapper {
		padding-bottom: 30px;
		position: relative;
	}

	.site-footer-info {
		padding-bottom: 20px;
	}

	.site-footer__logo {
		/* align-items: flex-start; */
		width: calc(100% - 500px);
	}

	.site-footer__logo span {
		display: none;
	}

	.site-footer__logo p {
		display: none;
	}

	.site-footer__logo img {
		display: block;
	}

	.site-footer__contact--item {
		flex-direction: column;
		align-items: space-between;
		flex-grow: 1;

	}

	.site-footer__contact--text {
		padding-left: 0;
		padding-top: 15px;
		text-align: center;
		font-size: 20px;
		line-height: 30px;
	}
	
	.site-footer__contact--item:first-child {
		margin-right: 50px;
		padding-right: 0;
	}

	.site-footer__icon {
		position: absolute;
		bottom: -10px;
		left: -4px;
	}

	.site-footer__copy-wrapper {
		margin-top: 15px;
		margin-bottom: 15px;
		padding: 0;
	}

	.site-footer__copyright {
		font-size: 16px;
	}
}

@media (max-width: 767px) {
	.site-footer-info__wrapper {
		flex-direction: column;
		align-items: center;
		padding-bottom: 100px;
	}

	.site-footer__contact {
		flex-direction: column;
		align-items: center;			
	}

	.site-footer__copy-wrapper {
		flex-direction: column;
		align-items: center;
		padding-top: 25px;
		padding-bottom: 25px;
	}

	a.site-footer__logo {
		margin-right: 0;
		width: 100%;
	}

	.site-footer__logo img {
		display: block;
		margin-top: 0;
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
	}

	.site-footer__contact--item {
		padding-top: 35px;
	}

	.site-footer__contact--item:first-child {
		padding-right: 0;
		margin: 0;
	}

	.site-footer__icon {
		padding-top: 55px;
		padding-bottom: 20px;
	}

	.site-footer__icon {
		bottom: 0px;
		left: calc(50% - 60px);
	}

	.site-footer__copyright p {
    margin-bottom: 10px;
}
}


.site-footer__contact .iconYellow {
	fill: #ffa000;
}

.site-footer__icon a {
	@apply --transitionOpacityStart;
}

.site-footer__icon a:hover {
	@apply --transitionOpacityEnd;
}

.site-footer__icon .socFill {
	fill: #a0a0a0;
}