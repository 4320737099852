.category-subcategories {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.category--item {
	display: flex;
	flex-direction: column;
	flex-basis: calc(33% - 15px);
	background-color: white;
	box-shadow: 0 0 5px rgba(220, 220, 220, 0.75);
	margin-bottom: 30px;
}

.category--item a {
	width: 100%;
	display: block;
}

.category--item img {
	width: 100%;
	height: 100%;
	min-height: 240px;
	object-fit: cover;
	@apply --transitionOpacityStart;
}

.category--item .item-name {
	padding: 15px 20px;
}

.category--item .item-name span {
	display: block;
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 18px;
	font-weight: 600;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 1px;
	padding: 10px 20px;
	border-left: 2px solid #ffa000;
	transition: border-left-width .3s;
}

.category--item:hover {
	box-shadow: 0 0 10px rgba(220, 220, 220, 0.75);
}

.category--item:hover img {
	@apply --transitionOpacityEnd;
}

.category--item:hover .item-name span {
	border-left-width: 12px;
}

@media (width <= 1199px) {
	.category--item img {
		min-height: 195px;
	}
}

@media (width <= 767px) {
	.category-subcategories {
		flex-wrap: wrap;
		justify-content: center;
	}

	.category--item {
		flex-basis: 100%;
		margin-bottom: 20px;
		max-width: 460px;
	}

	.category--item img {
		max-width: 460px;
		max-height: 235px;
	}

	.category--item .item-name span {
	border-left: none;
	text-align: center;
}

}