@font-face{
	font-family: "Myriad Pro";
	src: url(../fonts/MyriadProRegular.otf);
}

@font-face{
	font-family: "Myriad Pro Bold";
	src: url(../fonts/MyriadProSemiBold.otf);
}

.site-header-info__wrapper {
	background-color: #f9f7f7;
}

.site-header-info {
	display: flex;
	justify-content: space-between;
	padding-top: 15px;
	padding-bottom: 15px;
}

.site-header-info__contact {
	display: flex;
	flex-wrap: wrap;
}

.site-header-info__contact a {
	will-change: opacity;
	opacity: 1;
	transition: opacity .3s;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 25px;
}

.site-header-info__contact a+a {
	margin-right: 0;
}


.site-header-info__contact a:hover {
	opacity: 0.7;
}

.site-header-info__contact span {
	padding-right: 15px;
	padding-left: 15px;
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 16px;
	line-height: 22px;
}

.site-header-info__download a {
	will-change: opacity;
	opacity: 1;
	transition: opacity .3s;
}

.site-header-info__download a:hover {
	opacity: 0.7;
}

.site-header-info__download svg {
	display: inline-block;
	margin-top: 4px;
}

.site-header-info__download span {
	display: inline-block;
	margin-left: 15px;
	color: #ffa000;
	font-family: "Myriad Pro Bold", sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;
	text-transform: uppercase;
	vertical-align: top;
	border-bottom: 2px dotted #ffa000;
}

.site-header-info__download a {
	text-decoration: none;
}

.site-header-menu {
	display: flex;
	justify-content: space-between;
}

.site-header-menu__logo {
	display: flex;
	align-items: center;
}

.site-header-menu__logo img {
	position: relative;
	left: -15px;
}

@media (width <= 1390px) {
	.site-header-menu__logo {
		position: static;
	}
}

@media (width <= 1220px) {
	.site-header-menu__logo img {
		left: 0;
	}

/* 	.site-header-menu__logo-text__wrapper {
		left: -10px;
	} */
}

@media (width <= 1199px) {
	.site-header-menu__logo img {
		left: -15px;
	}
/* 
	.site-header-menu__logo-text__wrapper {
		left: -25px;
	} */
}

@media (width <= 1100px) {
	.site-header-menu__logo img {
		left: 0;
	}

/* 	.site-header-menu__logo-text__wrapper {
		left: -10px;
	} */
}

@media (width <= 992px) {
	.site-header-menu__logo img {
		left: -15px;
	}

/* 	.site-header-menu__logo-text__wrapper {
		left: -25px;
	} */
}

@media (width <= 790px) {
	.site-header-menu__logo img {
		left: 0;
	}

/* 	.site-header-menu__logo-text__wrapper {
		left: -10px;
	} */
}

@media (width <= 767px) {
	.site-header-menu__logo .navbar-brand {
		padding-left: 0;
		padding-right: 0;
	}

	.site-header-menu .site-header-menu__logo img {
		margin-left: 0;
		left: 10px;
	}
}

/* .site-header-menu__logo-text__wrapper {
	display: flex;
	flex-wrap: wrap;
	width: 250px;
	position: relative;
	left: -25px;
	z-index: -1;
} */


.site-header-menu__logo a {
	display: flex;
	justify-content: flex-start;
	height: auto;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	vertical-align: bottom;
	will-change: opacity;
	opacity: 1;
	transition: opacity .3s;
}

.site-header-menu__logo a:hover {
	opacity: 0.7;
}

/* .site-header-menu__logo a span {
	padding-right: 15px;
}

.site-header-menu__logo a span:first-of-type {
	padding-left: 5px;
}

.site-header-menu__logo a span:last-of-type {
	padding-top: 5px;
}

.site-header-menu__logo--main {
	color: #ffa000;
	font-family: "Myriad Pro", sans-serif;
	font-size: 79px;
	font-weight: 700;
	line-height: 5px;
	padding-top: 10px;
}

.site-header-menu__logo--text {
	color: #444;
	font-family: "Myriad Pro", sans-serif;
	font-size: 17px;
	font-weight: 600;
	line-height: 10px;
	letter-spacing: 1px;
} */

.site-header-menu__nav {
	padding-left: 0;
	padding-right: 0;
}

.site-header-menu__nav li a {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 14px;
	line-height: 18px;
	text-transform: uppercase;
	padding-top: 45px;
	padding-bottom: 45px;
	padding-right: 0px;
	padding-left: 0px;
	margin-right: 12px;
	margin-left: 12px;
	text-decoration: none;
}

.site-header-menu__nav li:first-child a {
	margin-left: 0;
}

.site-header-menu__nav li:last-child a {
	margin-right: 0;
}

.site-header-menu__nav  .navbar-nav > li > a {
	border-top: 3px solid transparent;
	transition: border-color .3s;
}

.site-header-menu__nav  .navbar-nav > li:hover > a {
	border-top: 3px solid #ffa000;
}

.nav>li>a:focus,
.nav>li>a:hover {
	background-color: transparent;
}

.navbar-nav {
	position: relative;
	z-index: 20;
}

.navbar-nav li {
	/* position: relative; */
	position: static;
}

.sub-nav {
	/* display: none; */
	left: 0;
	margin-left: -20px;
	visibility: hidden;
	position: absolute;
	padding-left: 0;
	width: 100%;
	min-width: 260px;
	background-color: transparent;
	transition: background-color .3s;
	z-index: 2;
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.sub-nav {
		min-width: 290px;
	}
}

.sub-nav li {
	list-style: none;
	padding: 10px 20px;
}

.sub-nav li:first-child {
	padding-top: 24px;
}

.sub-nav li:last-child {
	padding-bottom: 24px;
}

.sub-nav li a {
	color: white;
	padding: 0;
	margin: 0;
	text-decoration: none;
	transition: color .3s;
	width: 100%;
	display: inline-block;
}

.sub-nav li a:hover {
	color: #ffa000;
	border: none;
}

.navbar-nav > li:hover .sub-nav {
	/* display: block; */
	visibility: visible;
	background-color: #222;
}

@media (max-width: 991px) {
	.sub-nav li {
		padding-left: 10px;
	}
}

@media (max-width: 767px) {
	.site-header-info {
		justify-content: center;
	}

	.site-header-info__contact span {
		font-size: 20px;
	}

	.site-header-info__contact span:first-of-type {
		padding-right: 50px;
	}
	.site-header-info__contact span:last-child {
		padding-right: 0;
	}

	.site-header-info__download {
		display: none;
	}

/* 	.site-header-menu__logo--main {
		display: none;
	}

	.site-header-menu__logo--text {
		display: none;
	} */

	.site-header-menu__logo img {
		display: block;
		z-index: 2;
	}

	.site-header {
		position: relative;
	}

	.site-header-menu__nav {
		position: absolute;
		top: 125px;
		left: 0;
		width: 100%;
		z-index: 1;
	}

	.site-header-menu__nav .navbar-nav  {
		margin: 0;
		display: block;
	}

	.site-header-menu__nav .navbar-nav li {
		background-color: #FFDE00;
		margin-bottom: 2px;
		transition: background-color .3s;
	}

	.site-header-menu__nav li a {
		padding-top: 10px;
		padding-bottom: 10px;
		margin: 0;
	}

	.site-header-menu__nav li:last-child a {
		margin-right: 0;
	}

	.navbar-toggle {
		position: absolute;
		bottom: 15px;
		right: 0px;
		margin: 0;
	}

	.navbar-toggle .icon-bar {
		display: block;
		width: 30px;
		height: 2px;
		background-color: #ffa000;
		border-radius: 0;
	}

	.navbar-toggle .icon-bar+.icon-bar {
		margin-top: 5px;
	}

	.banner__catalog-menu--dropdown .navbar-toggle {
		position: static;
		background-color: #fafafa;
		width: 100%;
		color: #222;
		font-family: "Myriad Pro", sans-serif;
		font-size: 32px;
		font-weight: 400;
		line-height: 38px;
		text-transform: uppercase;
		display: flex;
		justify-content: center;
		align-items: center;		
		border-radius: 0;
		padding-bottom: 10px;
	}

	.banner__catalog-menu--dropdown .navbar-toggle p {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		margin-bottom: 0;
	}

	.banner__catalog-menu--dropdown .navbar-toggle:not(.collapsed) {
		border-bottom: 1px solid #e2e2e2;
	}

	.banner__catalog-menu--dropdown .navbar-toggle span {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 44px;
		height: 44px;
		border: 1px solid #222;
		margin-left: 20px;
	}

	.banner__catalog-menu--dropdown .navbar-toggle.collapsed svg {
		transform: rotate(-90deg);
		transition: transform .3s;
	}

	.banner__catalog-menu--dropdown .navbar-toggle svg {
		transform: rotate(90deg);
		transition: transform .3s;
	}

}

@media (max-width: 479px) {
	.site-header-info__contact{
		width: 100%;
	}

	.site-header-info__contact a {
		margin-right: 0;
		margin-bottom: 10px;
		flex-basis: 100%;
		width: 100%;
	}
}


.site-header-info .iconYellow {
	fill: #ffa000;
	transition: fill .3s, opacity .3s;
	will-change: opacity;
	opacity: 1;

}

.site-header-info a:hover .iconYellow {
	fill: #222;
	opacity: 0.7;
}

@media (width >= 768px) {
	.banner__catalog-menu ul.collapse.in {
		display: flex;
	}
}


.site-header.extra-space  {
	margin-bottom: 90px;
}