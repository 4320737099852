.customers__wrapper {
	padding-bottom: 50px;
	margin-top: 80px;
}

.customers__text {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

.customers__text h2 {
	color: #444;
	font-family: "Myriad Pro", sans-serif;
	font-size: 32px;
	line-height: 38px;
	text-transform: uppercase;
	margin: 0;
}

.customers__items {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
	flex-grow: 1;
}

.customers__items a {
	opacity: 0.25;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 123px;
}

.customers__items a:hover {
	opacity: 1;
	transition: opacity 1s;
}

.customers__items .owl-item img {
	width: 100%;
	height: auto;
	max-width: 293px;
}

@media (max-width: 991px) {
	.customers__items a {
		padding-left: 20px;
		padding-right: 20px;
	}

	.customers__items a:first-child {
		padding-left: 0;
	}

	.customers__items a:last-child {
		padding-right: 0;
	}
}

@media (max-width: 767px) {
	.customers__wrapper {
		margin-top: 70px;
	}

	.customers__items {
		-webkit-justify-content: center;
		justify-content: center;
	}
	.customers__items a:nth-child(3){
		display: none;
	}

	.customers__items a:nth-child(4){
		display: none;
	}
}

@media (max-width: 479px) {
	.customers__items a{
		padding-left: 10px;
		padding-right: 10px;
	}

	.customers__items a:first-child {
		padding-left: 0;
	}

	.customers__items a:last-child {
		padding-right: 0;
	}
}

@media (max-width: 355px) {
	.customers__items a:nth-child(2){
		display: none;
	}
}