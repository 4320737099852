@import url(./blocks/index--banner.css);
@import url(./blocks/carousel-control.css);
@import url(./blocks/catalog-menu.css);
@import url(./blocks/index--about.css);
@import url(./blocks/index--advantages.css);
@import url(./blocks/index--customers.css);
@import url(./blocks/index--questions.css);
@import url(./blocks/index--map.css);
@import url(./blocks/about--info.css);
@import url(./blocks/about--menu.css);
@import url(./blocks/responsive-menu.css);
@import url(./blocks/title.css);
@import url(./blocks/breadcrumbs.css);
@import url(./blocks/modal-call.css);
@import url(./blocks/contacts__request.css);
@import url(./blocks/contacts__details.css);
@import url(./blocks/contacts__wrapper.css);
@import url(./blocks/page-wrapper.css);
@import url(./blocks/main-content.css);
@import url(./blocks/main-content-blog.css);
@import url(./blocks/sidebar.css);
@import url(./blocks/category-subcategories.css);
@import url(./blocks/category-text.css);
/* @import url(./blocks/production-content.css); */
@import url(./blocks/production-info.css);
@import url(./blocks/production-special-offer.css);
@import url(./blocks/production-table-name.css);
@import url(./blocks/table.css);
@import url(./blocks/production-button.css);
@import url(./blocks/services-info.css);
@import url(./blocks/services-photo.css);
@import url(./blocks/blog-content.css);
@import url(./blocks/blog-inner-content.css);
@import url(./blocks/pagination.css);
@import url(./blocks/arrow_up.css);
