.page-pagination {
	display: flex;
	justify-content: center;
	margin-top: 50px;
	margin-bottom: 90px;
}

.page-pagination ul {
	display: flex;
	list-style: none;
	padding-left: 0;
}

.page-pagination a {
	color: #424242;
	font-family: "Myriad Pro", sans-serif;
	font-size: 16px;
	font-weight: 400;
	width: 100%;
	text-align: center;
	line-height: 44px;
	transition: color .3s;
}

.page-pagination .pageFill {
	fill: black;
	transition: fill .3s;
}

.page-pagination li {
	background-color: #f4f2f2;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
	border-radius: 9px;
	transition: background-color .3s;
	margin-right: 10px;
}

.page-pagination li.active:not(.disabled),
.page-pagination li:hover:not(.disabled) {
	background-color: #ffa000;
}

.page-pagination li.active a,
.page-pagination li:hover a {
	color: #FFF;
}

.page-pagination li:not(.disabled) .pageFill {
	fill: black;
}

.page-pagination .disabled a {
	cursor: default;
}

.page-pagination li:not(.disabled):hover .pageFill {
	fill: white;
}

.page-pagination li:not(:first-child) svg {
	transform: rotate(180deg);
}

@media (width <= 767px) {
	.page-pagination {
		margin-bottom: 70px;
	}
}

@media (width <= 479px) {
	.page-pagination a {
		font-size: 14px;
	}

	.page-pagination li {
		width: 30px;
		height: 30px;
		margin-right: 5px;
	}

	.page-pagination a {
		line-height: 30px;
	}
	
}