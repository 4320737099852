.advantages {
	background-color: #f9f7f7;
}

.advantages__wrapper {
	display: flex;
	justify-content: space-between;
	margin-top: 65px;
	margin-bottom: 60px;
}

.advantages__item {
	display: flex;
	flex-direction: column;
	flex-basis: calc(33% - 110px);
	align-items: center;
	margin-right: 75px;
	margin-left: 75px;
}

.advantages__item:first-child {
	margin-left: 0;
}

.advantages__item:last-child {
	margin-right: 0;
}

.advantages__item p {
	width: 100%;
	text-align: center;
	color: #666;
	font-family: "Myriad Pro", sans-serif;
	font-size: 16px;
	line-height: 32px;
	padding-top: 25px;
	margin-bottom: 0;
}

@media (max-width: 1199px) {
	.advantages__item {
		flex-basis: calc(33% - 70px);
		margin-right: 45px;
		margin-left: 45px;
	}

	.advantages__item:first-child {
		margin-left: 0;
	}

	.advantages__item:last-child {
		margin-right: 0;
	}
}

@media (max-width: 991px) {
	.advantages__item {
		flex-basis: calc(33% - 35px);
		margin-right: 25px;
		margin-left: 25px;
	}

	.advantages__item:first-child {
		margin-left: 0;
	}

	.advantages__item:last-child {
		margin-right: 0;
	}
}

@media (max-width: 767px) {
	.advantages__wrapper {
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}

	.advantages__item {
		flex-basis: 100%;
		max-width: 500px;
		margin-right: 0;
		margin-left: 0;
		padding-bottom: 25px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.advantages__item:first-child {
    margin-right: 0;
}

	.advantages__item:last-of-type {
		padding-bottom: 0;
		margin-left: 0;
	}

	.advantages__item p {
		padding-top: 35px;
	}

	.advantages__item p:first-child {
		padding-top: 0;
	}
}

.advantages__item .advantages__img-wrapper {
	width: 83px;
	height: 83px;
	border-radius: 50%;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	transition: background-color .3s;
}

.advantages__item .advantages__img-wrapper::after {
	width: 93px;
	height: 93px;
	top: -5px;
	left: -5px;
	content: "";
	position: absolute;
	border: 1px solid #e2e2e2;
	border-radius: 50%;
	transition: border-color .3s;
}

.advantages__item .iconYellow {
	fill: #ffa000;
	transition: fill .3s;
}

.advantages__item:hover .advantages__img-wrapper {
	background-color: #ffa000;
}

.advantages__item:hover .advantages__img-wrapper::after {
	border-color: #ffa000;
}

.advantages__item:hover .iconYellow {
	fill: white;
}