.responsive-only {
	display: none;
}

.navbar-collapse.in {
	overflow-y: scroll;
}
@media (width <= 767px) {
.heaver-responsive-menu__wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	overflow-y: scroll;
	min-height: 515px;
	background-color: #ffa000;
}

	.responsive-only {
		display: block;
		
	}
	.site-header-menu__nav {
		top: 0;
		left: 0;
		position: fixed;
		overflow: hidden;
		min-height: 515px;
		background-color: #ffa000;
		z-index: 150;
	}

	.responsive-only.navbar-brand {
		height: auto;
		position: absolute;
		top: 40px;
		left: 50%;
		transform: translateX(-50%);
		@apply --transitionOpacityStart;
	}

	.responsive-only.navbar-brand:hover {
		@apply --transitionOpacityEnd;
	}

	.site-header-menu__nav.in {
		min-height: 100vh;
	}

	.site-header-menu__nav .navbar-nav {
		max-width: 440px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 160px;
	}

	.site-header-menu__nav .navbar-nav li {
		background-color: transparent;
	}

	.site-header-menu__nav .navbar-nav > li > a {
		color: #FFF;
	}

	.site-header-menu__nav .navbar-nav > li:hover > a,
	.site-header-menu__nav .navbar-nav > li.active > a {
		color: #222;
	}

	.close-button {
		position: absolute;
		top: 15px;
		right: 15px;
		display: block;
		width: 50px;
		height: 50px;
		cursor: pointer;
		opacity: 1;
		will-change: opacity;
		transition: opacity .3s, transform .3s;
	}

	.close-button:hover {
		opacity: .7;
		transform: rotate(180deg);
	}

	.close-button::before,
	.close-button::after {
		width: 50px;
		height: 2px;
		background-color: white;
		content:"";
		position: absolute;
		top: 25px;
		left: 0px;
	}

	.close-button::before {
		transform: rotate(45deg);
	}

	.close-button::after {
		transform: rotate(-45deg);
	}

	.navbar-nav > li:hover .sub-nav {
		visibility: hidden;
	}

	.navbar-nav > li:hover .is-dropdown + .sub-nav {
		visibility: visible;
	}

	.respDropdown.is-dropdown + .sub-nav {
		visibility: visible;
		position: static;
		margin-left: 0;
	}

	.navbar-nav > li:hover .sub-nav {
		background-color: transparent;
	}

	.site-header-menu__nav li a {
		font-size: 30px;
	}

	.site-header-menu__nav .sub-nav a {
		color: #FFF;
		font-size: 20px;
	}

	.site-header-menu__nav .sub-nav li:hover a {
		color: #222;
	}

	.category-menu {
		margin-top: 40px;
		padding-left: 0;
		list-style: none!important;
		max-width: 440px;
		margin-left: auto;
		margin-right: auto;
	}

	.category-menu li {
		padding-top: 10px;
		padding-bottom: 10px;
		list-style: none;
	}

	.category-menu li a {
		color: white;
		transition: color .3s;
	}

	.category-menu li:hover a,
	.category-menu li.active a {
		color: #222;
	}

	.site-header-menu__nav .sub-nav li {
		padding-right: 10px;
	}
}

@media (width <= 479px) {
	.site-header-menu__nav {
		text-align: center;
	}

	.site-header-menu__nav li a {
		font-size: 20px;
	}

	.site-header-menu__nav .sub-nav a {
		font-size: 16px;
		color: #FFF;
	}
} 