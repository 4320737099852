.banner__catalog-menu {
	background-color: #f9f7f7;
}

.is-fixed.banner__catalog-menu {
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	background-color: #f9f7f7;
	z-index: 2;
	box-shadow: 0 4px 18px rgba(105, 100, 99, .14);
}

.is-fixed .banner__catalog-menu-fixed__wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.is-fixed .catalog-menu {
	width: auto;
}

.fixed-logo {
	display: none;
} 

.is-fixed .fixed-logo {
	display: inline-block;
	@apply --transitionOpacityStart;
}

/* .is-fixed .fixed-logo img {
	min-width: 130px;
	height: 35px;
} */

.is-fixed .fixed-logo:hover {
	@apply --transitionOpacityEnd;
}

/* .is-fixed .fixed-logo span {
	color: #ffa000;
	font-family: 'Roboto', sans-serif;
	font-size: 28px;
	font-weight: 700;
	text-decoration: none;
}  */

.fixed-button {
	display: none;
}

.is-fixed .fixed-button {
	display: inline-block;
	height: 46px;
	width: 235px;
	flex-basis: 235px;
	background-color: white;
	border-radius: 23px;
	box-shadow: 0 0 6px rgba(220, 220, 220, 0.75);
	text-decoration: none;
	border: 1px solid #ffa000;
	transition: background-color 0.3s, color .3s;
} 

.is-fixed .fixed-button span {
	display: block;
	margin: 0;
	letter-spacing: 1px;
	color: #ffa000;
	font-family: 'Roboto', sans-serif;
	font-size: 11px;
	font-weight: 900;
	line-height: 46px;
	text-transform: uppercase;
	text-align: center;
	width: 100%;
}

.is-fixed .fixed-button:hover {
	background-color: #ffa000;
	border: 1px solid #ffa000;
}

.is-fixed .fixed-button:hover span{
	color: white;
}

.is-fixed.banner__catalog-menu > ul {
	justify-content: center;
	width: 40%;
}

.is-fixed.banner__catalog-menu ul li a svg {
	display: none;
}

.banner__catalog-menu  ul {
	display: flex;
	justify-content: space-between;
	list-style: none;
	margin-bottom: 0;
	padding-left: 0;
	width: 100%;
}

.banner__catalog-menu  ul.catalog-menu.collapse {
	height: auto !important;
}

.banner__catalog-menu  ul.catalog-menu.collapsing {
	display: block !important;
}


.is-fixed.banner__catalog-menu  ul li {
	border-right: none;
	border-left: none;
}

.is-fixed.banner__catalog-menu  ul li:hover,
.is-fixed.banner__catalog-menu  ul li.active {
	background-color: transparent;
	border-left: none;
}

.is-fixed.banner__catalog-menu  ul li:hover a, 
.is-fixed.banner__catalog-menu  ul li.active a {
	color: #222;
}

.is-fixed.banner__catalog-menu ul li a {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 15px;
	font-weight: 600;
	line-height: 18px;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.banner__catalog-menu  ul li {
	flex-basis: auto;
	flex-grow: 1;
	text-align: center;
	border-right: 1px solid #e8e8e8;
	border-left: 1px solid white;
	background-color: #f9f7f7;
	transition: background-color .3s, border-color .3s;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 15;
}

.banner__catalog-menu  ul li:first-child {
	border-left: none;
}

.banner__catalog-menu  ul li:last-child {
	border-right: none;
}

.banner__catalog-menu--dropdown .catalog-menu li:last-of-type {
	border-right: none;
}

.banner__catalog-menu--dropdown .catalog-menu li:first-of-type {
	border-left: none;
}

.banner__catalog-menu--dropdown ul li:first-of-type {
	border-left: 1px solid transparent;
}

.banner__catalog-menu ul li:first-of-type {
	border-left: 1px solid transparent;
}

.banner__catalog-menu  ul li a {
	width: 100%;
	padding: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	color: #222;
	transition: color .3s;
	text-decoration: none;
	font-family: "Myriad Pro", sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
	text-transform: uppercase;
}

.is-fixed.banner__catalog-menu ul li a {
	padding: 15px;
}

.banner__catalog-menu  a svg,
.banner__catalog-menu  a span {
	margin: 5px 8px;
}

.banner__catalog-menu  ul li:hover,
.banner__catalog-menu  ul li.active {
	background-color: #ffa000;
	/* border-left: 1px solid #ffa000; */
}

.banner__catalog-menu  ul li:hover a, 
.banner__catalog-menu  ul li.active a {
	color: white;
}

.banner__catalog-menu  ul li .iconGray {
	fill: #222;
	transition: fill .3s;
}

.banner__catalog-menu  ul li:hover .iconGray, 
.banner__catalog-menu  ul li.active .iconGray {
	fill: white;
}

.banner__catalog-menu li {
	position: relative;
}

.banner__catalog-menu .sub-catalog-menu {
	visibility: hidden;
	display: flex;
	flex-direction: column;
	position: absolute;
	bottom: 85px;
	left: -1px;
	width: calc(100% + 1px);
	padding-left: 0;
	background-color: #222;
	transition: background-color .3s;
	z-index: 2;
}

.banner__catalog-menu--dropdown .sub-catalog-menu {
	top: 85px;
	bottom: auto;
}

.is-fixed.banner__catalog-menu .sub-catalog-menu {
	flex-direction: column-reverse;
	/* bottom: -147px; */
	/* left: -1px; */
	left: 0;
	right: 0;
	bottom: auto;
}

.is-fixed.banner__catalog-menu .sub-catalog-menu {
	background-color: #f9f7f7;
	box-shadow: 0 4px 18px rgba(105, 100, 99, .14);
} 

.is-fixed.banner__catalog-menu .sub-catalog-menu li {
	background-color: transparent;
}

.is-fixed.banner__catalog-menu .sub-catalog-menu li:hover {
	background-color: #FFF;
}

.is-fixed .catalog-menu > li:hover .sub-catalog-menu {
	visibility: visible;
}

.is-fixed .catalog-menu > li:hover .sub-catalog-menu li a {
	color: #222;
	text-decoration: none;
	transition: color .3s;
}

.catalog-menu > li:hover .sub-catalog-menu {
	visibility: visible;
}

.banner__catalog-menu .sub-catalog-menu li {
	border-bottom: 1px solid white;
	border-top: none;
	border-left: none;
	border-right: none;
}

.banner__catalog-menu--dropdown .sub-catalog-menu li {
	border-bottom: none;
	border-top:  1px solid white;
	border-left: none;
	border-right: none;
}

.sub-catalog-menu li a {
	color: white;
	text-align: center;
	text-decoration: none;
	transition: color .3s;
}

.banner__catalog-menu .sub-catalog-menu {
	/* background-color: transparent; */
	background-color: #f9f7f7;
}

.banner__catalog-menu .sub-catalog-menu li a{
	padding-top: 20px;
	padding-bottom: 20px;	
}

.banner__catalog-menu .sub-catalog-menu li {
	background-color: #222;
}

.banner__catalog-menu > li:hover .sub-catalog-menu {
	visibility: visible;
}

.button-dropdown {
	display: none;
}

@media (max-width: 1199px) {
	.is-fixed.banner__catalog-menu {
		display: none;
	}
}


@media (max-width: 991px) {
	.banner__catalog-menu .sub-catalog-menu {
		bottom: 111px;
		left: -1px;
	}

	.banner__catalog-menu--dropdown .sub-catalog-menu {
		top: 111px;
		bottom: auto;
	}
}


@media (max-width: 767px) {
/* 	.catalog-menu {
		overflow: hidden;
	} */

	.banner__catalog-menu {
		display: none;
	}

	.banner__catalog-menu--dropdown {
		display: block;
	}

	.banner__catalog-menu--dropdown .catalog-menu.in {
		display: block;
		border-top: 1px solid white;
	}

	.catalog-menu > li:hover .sub-catalog-menu {
		visibility: hidden;
	}


	.catalog-menu > li.is-dropdown .sub-catalog-menu {
		visibility: visible;
		position: static;
	}

	.catalog-menu > li.is-dropdown {
		display: block;
	}
	
	.banner__catalog-menu .sub-catalog-menu {
		left: 0;
    right: 0;
    width: 100%;
	}

	.banner__catalog-menu .sub-catalog-menu a {
		width: auto;
	}

	.banner__catalog-menu--dropdown .catalog-menu a svg {
		display: none;
	}

	.banner__catalog-menu--dropdown .catalog-menu a {
		display: flex;
		justify-content: space-between;
		max-width: 450px;
		margin-left: auto;
		margin-right: auto;
	}

	.catalog-menu.collapse:not(.in) {
		display: none;
	}

	.banner__catalog-menu--dropdown ul li:hover, 
	.banner__catalog-menu--dropdown ul li.active {
		background-color: #f9f7f7;
		border: none;
	}

	.banner__catalog-menu--dropdown ul li:hover a, 
	.banner__catalog-menu--dropdown ul li.active a {
		color: #222;
	}

	.banner__catalog-menu .sub-catalog-menu li {
		margin-bottom: 1px;
		background-color: #f9f7f7;
	}

	.banner__catalog-menu--dropdown ul li:first-child {
		margin-top: 20px;
	}

	.banner__catalog-menu--dropdown ul li a {
		color: #222;
		font-family: "Myriad Pro", sans-serif;
		font-size: 30px;
		font-weight: 400;
		line-height: 26px;
		text-transform: uppercase;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.banner__catalog-menu--dropdown ul li {
		border-left: none;
		border-right: none;
		background-color: #f9f7f7;
		position: relative;
		max-width: 450px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		display: block!important;
	}

	.banner__catalog-menu--dropdown .catalog-menu > li > span {
		visibility: hidden;
		display: flex;
		opacity: 0;
		justify-content: center;
		align-items: center;
		width: 44px;
		height: 44px;
		position: absolute;
		top: 1px;
		right: 0;
		background-color: #FFA000;
		pointer-events: none;
		/* transition: opacity .3s; */
	}

	.banner__catalog-menu--dropdown .catalog-menu > li:nth-child(1) > span {transition: opacity .3s;}
	.banner__catalog-menu--dropdown .catalog-menu > li:nth-child(2) > span {transition: opacity .3s .05s;}
	.banner__catalog-menu--dropdown .catalog-menu > li:nth-child(3) > span {transition: opacity .3s .1s;}
	.banner__catalog-menu--dropdown .catalog-menu > li:nth-child(4) > span {transition: opacity .3s .15s;}

	.banner__catalog-menu--dropdown .catalog-menu.in > li > span {
		visibility: visible;
		opacity: 1;
	}

	.banner__catalog-menu--dropdown ul li svg {
		transform: rotate(-90deg);
	}

	.banner__catalog-menu--dropdown .catalog-menu > li.is-dropdown svg {
		transform: rotate(90deg);
	}

	.banner__catalog-menu--dropdown ul li .pageFill {
		fill: white;
	}

	.banner__catalog-menu--dropdown ul li.is-dropdown .pageFill {
		fill: #222;
	}

	.banner__catalog-menu--dropdown .sub-catalog-menu {
		max-width: 450px;
		margin-left: auto;
		margin-right: auto;
	}

	.banner__catalog-menu--dropdown .sub-catalog-menu li a{
		color: #222;
		font-family: "Myriad Pro", sans-serif;
		font-size: 20px;
		font-weight: 400;
		line-height: 42px;
		text-transform: uppercase;
		padding: 0;
		margin-left: 40px;
	}

	.banner__catalog-menu-fixed__wrapper a.button-dropdown {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 300px;
		height: 60px;
		border: 1px solid #ffa000;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
		margin-top: 40px;
		background-color: transparent;
		transition: background-color .3s;
	}

	.banner__catalog-menu--dropdown .sub-catalog-menu li {
		border-top: none;
	}

	.banner__catalog-menu-fixed__wrapper a.button-dropdown:hover {
		background-color: #ffa000;
	}

	.button-dropdown span {
		color: #ffa000;
		font-family: 'Roboto', sans-serif;
		font-size: 14px;
		font-weight: 900;
		line-height: 12px;
		text-transform: uppercase;
		letter-spacing: 2px;
		transition: color .3s;
	}

	.button-dropdown:hover span {
		color: #fafafa;
	}

}


@media (width <= 479px) {
	.banner__catalog-menu--dropdown ul li a {
		font-size: 20px;
	}

	.banner__catalog-menu--dropdown .sub-catalog-menu li a {
		font-size: 18px;
	}
	.banner__catalog-menu--dropdown .navbar-toggle span {
		width: 34px;
		height: 34px;
	}
	.banner__catalog-menu--dropdown .catalog-menu > li > span {
		width: 30px;
		height: 30px;
		top: 10px;
		right: 30px;
	}
	.banner__catalog-menu-fixed__wrapper a.button-dropdown {
		height: 50px;
		width: 270px;
	}
}





@media (width <= 767px) {
	.banner__catalog-menu  .collapsing {
		max-height: 344px!important;
	}
}

.is-fixed .banner__catalog-menu-fixed__wrapper .sub-catalog-menu {
	top: 58px;
}