.breadcrumb {
	width: 100%;
	flex-basis: 100%;
	text-align: center;
	background-color: transparent;
	padding: 0;
	margin-top: 0;
	margin-bottom: 25px;
	margin-left: 0;
	margin-right: 0;
}

.breadcrumb li a {
	display: inline-block;
	text-decoration: none;
	font-family: "Myriad Pro", sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 38px;
	color: #ffa000;
}

.breadcrumb .active a {
	color: #222;
	cursor: default;
}

.breadcrumb a:focus {
	text-decoration: none;
}

.breadcrumb > li + li {
	margin-left: 10px;
	position: relative;
}

.breadcrumb > li + li::before {
	content: "/";
	line-height: 1;
	color: #0b0c0f;
	font-size: 13px;
	display: block;
	position: absolute;
	left: -13px;
	top: 13px;
}

@media (width >= 768px) {
	.breadcrumb {
		padding-left: 0;
		padding-right: 0;		
	}
}

@media (width <= 767px) {
	.breadcrumb {
		display: none;
	}
} 
