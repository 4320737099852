.production-table {
	max-width: 100%;
	overflow-x: auto;
}

table {
	border: none!important;
	width: 100%;
	max-width: 100%;
	background-color: white;
	box-shadow: 0 0 10px rgba(220, 220, 220, 0.75);
}

table > thead > tr > th {
	padding: 5px 10px;
	line-height: 1;
	vertical-align: top;
	color: white;
	text-transform: none;
	background-color: #ffa000;
	font-family: "Myriad Pro", sans-serif;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 1px;
	text-align: center;
	vertical-align: middle;
	border-bottom: 1px solid rgba(255, 255, 255, .36);
	border-right: 1px solid rgba(255, 255, 255, .36);
} 

.item-th {
	white-space: nowrap;
}

.item-th-width {
	mix-width: 100px;
}

table > thead > tr:first-child > th {
	padding-top: 5px;
}

table > thead > tr > th:last-child {
	border-right: none;
}

table > tbody > tr > td {
	padding: 5px 10px;
	line-height: 1.2;
	vertical-align: top;
	text-transform: none;
	border-right: 1px solid #cecece;
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 1px;
	text-align: center;
	white-space: nowrap;
}

table > tbody > tr > td:last-child {
	border-right: none;
}

table > tbody > tr:nth-child(2n+2) > td {
	background-color: rgba(250, 236, 204, 1);
}

table > tbody > tr:last-child > td {
	background-color: rgba(250, 236, 204, 1);
	text-align: center;
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
	padding-top: 20px;
	padding-bottom: 20px;
	letter-spacing: 1px;
	border-bottom: none;
}