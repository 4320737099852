.brand-menu__wrapper {
	height: 310px;
	background-image: url('../img/about-us/constructions-1656223_1920.jpg');
	background-repeat: no-repeat;
	background-position: center bottom;
	background-attachment: fixed;
	obfect-fit: cover;
	width: 100%;
	position: relative;
}

.brand-menu__overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0.85;
	background-color: #ffa000;
}

.brand-menu {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 310px;
	position: relative;
	z-index: 1;
}

.brand-menu a {
	flex-basis: 280px;
	width: 280px;
	height: 130px;
	background-color: rgba(255, 255, 255, 1);
	text-decoration: none;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	transition: background-color .3s;
}

.brand-menu .img-wrapper {
	padding-left: 30px;
}

.brand-menu a:hover {
	background-color: rgba(255, 255, 255, .9);
}

.brand-menu .img-wrapper {
	flex-basis: 50px;
}

.brand-menu svg {
	margin-right: 15px;
}

.brand-menu  a .iconGray {
	fill: #8f8f8f;
	transition: fill 0.3s;
}

.brand-menu  a:hover .iconGray, 
.brand-menu  a.active .iconGray {
	fill: #ffa000;
}

.brand-menu span:first-of-type {
	display: inline-block;
	width: 2px;
	height: 58px;
	background-color: #cecece;
	vertical-align: middle;
	transition: background-color .3s;
}

.brand-menu a:hover span:first-of-type {
	background-color: #ffa000;
}

.brand-menu span:last-child {
	padding-left: 20px;
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	text-transform: uppercase;
	max-width: 155px;
	transition: color .3s;
}

.brand-menu a:hover span:last-child {
	color: #ffa000;
}

@media (max-width: 1199px) {
	.brand-menu__wrapper {
		height: 470px;
	}

	.brand-menu {
		flex-wrap: wrap;
		justify-content: center;
		align-content: center;
		height: 470px;
	}

	.brand-menu a {
		flex-basis: 340px;
		width: 340px;
		margin-right: 30px;
		margin-bottom: 30px;
	}

	.brand-menu a:nth-child(3), .brand-menu a:last-child {
		margin-bottom: 0;
	}

	.brand-menu a:nth-child(2), .brand-menu a:last-child {
		margin-right: 0;
	}
}

@media (max-width: 991px) {
	.brand__info img {
		float: none;
		padding-right: 0;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (max-width: 767px) {
	.brand-menu__wrapper {
		height: 615px;
	}

	.brand-menu {
		height: 615px;
		justify-content: space-between;
		align-content: center;
		flex-basis: 460px;
		width: 460px;
		margin-right: auto;
		margin-left: auto;
	}

	.brand-menu a {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 40px;
		margin-right: 0;
		flex-basis: 220px;
		width: 220px;
		height: 210px;
	}

	.brand-menu .img-wrapper {
		padding-left: 0;
	}

	.brand-menu a:nth-child(3), .brand-menu a:last-child {
		margin-bottom: 0;
	}


	.brand-menu span:last-child {
		padding-left: 0;
		padding-top: 20px;
		text-align: center;
	}

	.brand-menu img {
		padding-right: 0;
		padding-bottom: 20px;
	}

	.brand-menu span:first-of-type {
		width: 58px;
		height: 2px;
	}

	.brand-menu span:last-child {
		padding-left: 0;
		padding-top: 20px;
	}

	.brand-menu svg {
		margin-right: 0;
		margin-bottom: 20px;
	}
}

@media (max-width: 479px) {
	.brand-menu__wrapper {
		height: 790px;
	}

	.brand-menu {
		height: 790px;
		width: 100%;
	}

	.brand-menu span:last-child {
		text-align: left;
	}

	.brand-menu a {
		flex-direction: row;
		flex-basis: 280px;
		width: 280px;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 30px;
		padding-top: 0;

		align-items: center;
		justify-content: flex-start;

		height: 130px;
	}

	.brand-menu a:nth-child(3) {
		margin-bottom: 30px;
	}

	.brand-menu a:last-child {
		margin-bottom: 0;
	}

	.brand-menu a:nth-child(2), 
	.brand-menu a:last-child {
		margin-right: auto;
	}

	.brand-menu span:first-of-type {
		width: 2px;
		height: 58px;
	}

	.brand-menu .img-wrapper {
		flex-basis: 50px;
		padding-left: 20px;
	}

	.brand-menu img {
		padding-right: 15px;
	}

	.brand-menu span:last-child {
		padding-left: 20px;
		padding-top: 0;
	}

	.brand-menu img {
		padding-bottom: 0;
	}

	.brand-menu svg {
		margin-right: 15px;
		margin-bottom: 0;
	}
}