.blog-inner {
	padding: 40px;
	box-shadow: 0 0 10px rgba(220, 220, 220, 0.75);
	margin-bottom: 70px;
}

.blog-inner-date {
	padding-bottom: 20px;
}

.blog-inner-date p span {
	font-family: "Myriad Pro", sans-serif;
	font-size: 14px;
	font-weight: 600;
	line-height: 14px;
	color: #ffa000;
	letter-spacing: 1px;
}

.blog-inner-date p span:nth-child(2) {
	display: inline-block;
	color: #222;
	padding-right: 5px;
	padding-left: 5px;
}

.blog-inner-info img {
	display: block;
	float: left;
	max-width: 600px;
	/* height: 420px; */
	height: auto;
	padding-right: 40px;
	padding-bottom: 30px;
}

.blog-inner-text p {
	color: #444;
	font-family: "Myriad Pro", sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 31px;
	text-align: left;
}

.blog-inner-text ol,
.blog-inner-text ul {
	list-style: none;
}


.blog-inner-text ol {
	counter-reset: my-counter;
}

.blog-inner-text li {
	position: relative;
	color: #444;
	font-family: "Myriad Pro", sans-serif;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 15px;
}

.blog-inner-text ul li::before {
	position: absolute;
	top: 5px;
	left: -26px;
	display: block;
	width: 6px;
	height: 10px;
	background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAKBAMAAABlIDIAAAAAElBMVEUAAAD/oQD/oQD/oQD/oQD/oQCmJd1/AAAABnRSTlMA3x+oPAdXhUhUAAAAIElEQVQI12NgZWBgcFRgYGAWAjIMwQwQBrGBFFgOpAYAI34Bw0HsqRYAAAAASUVORK5CYII=');
	content: "";
}

.blog-inner-text ol li::before {
	position: absolute;
	top: -3px;
	left: -40px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	background-color: #ffa000;
	content: counter(my-counter);
	counter-increment: my-counter;
	font-family: "Myriad Pro", sans-serif;
	color: white;
	font-size: 14px;
	line-height: normal;
}

.blog-inner-text h1 {
	color: #ffa000;
	font-family: "Myriad Pro", sans-serif;
	font-size: 32px;
	font-weight: 600;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.blog-inner-text h2 {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 22px;
	font-weight: 600;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.blog-inner-text h3 {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 20px;
	font-weight: 600;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.blog-inner-text h4 {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 18px;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 1px;
}


#disqus_thread {
	padding-top: 40px;
}

@media (max-width: 991px) {
	.blog-inner {
		padding-right: 0;
		padding-left: 0;
	}

	.blog-inner-date {
		padding-left: 30px;	
	}

	.blog-inner-info img {
		width: 100%;
		float: none;
		padding-right: 0;
		margin-left: auto;
		margin-right: auto;
	}

	.blog-inner-text {
		padding-left: 30px;
		padding-right: 30px;
		word-break: break-all;
	}

	#disqus_thread {
	padding-left: 30px;
	padding-right: 30px;
}
}

@media (max-width: 767px) {
	.blog-inner {
		box-shadow: none;
		padding: 0;
	}

	.blog-inner-date {
		padding-top: 20px;
		padding-bottom: 10px;
		padding-left: 10px;
	}

	.blog-inner-info img {
		width: 100%;
		object-fit: cover;
	}

	.blog-inner-text {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 767px) {
	.blog-inner-info img {
		height: auto;
	}
}

@media (max-width: 767px) {
	_:-ms-lang(x), _:-webkit-full-screen, .blog-inner-info img {
		height: auto;
	}
}

