.contacts__request {
	flex-basis: 50%;
}

@media (width <= 991px) {
	.contacts__request {
		flex-basis: 100%;
		padding-left: 40px;
	}
}

.contacts__request form {
	padding-top: 45px;
	padding-bottom: 45px;
	padding-left: 0;
	padding-right: 30px;
}

@media (width <= 767px) {
	.contacts__request {
		padding: 0 40px;
	}

	.contacts__request form {
		margin-right: auto;
		margin-left: auto;
		padding-left: 0;
		padding-right: 0;
	}
}

@media (width <= 479px) {
	.contacts__request form {
		padding-left: 15px;
		padding-right: 15px;
	}

	.contacts__request {
		padding-left: 0;
		padding-right: 0;
	}
}


.form-group {
	margin-bottom: 30px;
	position: relative;
}

.contacts__request .form-group input {
	height: 50px;
}

.contacts__request .form-group textarea {
	min-height: 150px;
	resize: vertical;
}

.contacts__request  input,
.contacts__request  textarea {
	color: #0b0c0f;
	font-size: 16px;
	padding: 5px 20px;
	border: 1px solid #959595;
	line-height: normal;
}

.contacts__request-handler {
	position: relative;
}

::-webkit-input-placeholder {
	color: #999;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 1px;
	text-overflow:ellipsis;
}

textarea[placeholder] {
	padding-top: 15px;
}

.contacts__request .form-control {
	border-radius: 0;
}

.contacts__request .form-control:focus {
	border-color: #ffa000;
}

.contacts__request button {
	width: 235px;
	height: 46px;
	border-radius: 23px;
	margin-top: 30px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	border: solid 1px #ffa000;
	background-color: #ffa000;
	font-family: 'Roboto', sans-serif;
	font-size: 11px;
	font-weight: 900;
	line-height: 12px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: white;
	text-align: center;
	transition: color .3s, background-color .3s;
}

.contacts__request button:hover {
	background-color: white;
	color: #ffa000;
}