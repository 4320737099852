.main-content-blog {
	flex-basis: calc(100% - 340px);
}

@media (width <= 1199px) {
	.main-content-blog {
		margin-right: 20px;
		margin-left: 20px;
	}
}

@media (width <= 991px) {
	.main-content-blog {
		flex-basis: 100%;
		width: 100%;
		margin-right: 0;
		margin-left: 0;
	}
}

@media (width <= 767px) {
	.main-content-blog {
		padding: 0 10px;
		margin-top: 50px;
	}
}

@media (width <= 479px) {
	.main-content-blog {
		padding: 0;
	}
}