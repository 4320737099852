.production-name {
	padding-bottom: 5px;
}

.production-name h3 {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 26px;
	font-weight: 600;
	line-height: 1;
	text-align: center;
	margin-top: 0;
	margin-bottom: 20px;
}