.back-to-top {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  right: 20px;
  width: 55px;
  height: 55px;
  margin: 1em;
  background-color: #b5b5b5;
  color: white;
  box-shadow: .5px .5px .5px 1px rgba(0, 0, 0, .1);
}

.back-to-top svg {
	width: 13px;
	height: 23px;
	transform: rotate(90deg);
}

.back-to-top svg path {
	fill: white;
}

.back-to-top:hover {
  cursor: pointer;
  background-color: #a0a0a0;
  box-shadow: .5px .5px .5px 1px rgba(0, 0, 0, .2);
}