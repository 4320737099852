.brand__wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 70px;
}

.brand__info {
	padding-top: 40px;
	padding-bottom: 40px;
	padding-left: 40px;
	padding-right: 40px;
	box-shadow: 0 0 10px rgba(220, 220, 220, 0.75);
}

.brand__info p {
	color: #444;
	font-family: "Myriad Pro", sans-serif;
	font-size: 14px;
	line-height: 31px;
}

.brand__info ol,
.brand__info ul {
	list-style: none;
}


.brand__info ol {
	counter-reset: my-counter;
}

.brand__info li {
	position: relative;
	color: #444;
	font-family: "Myriad Pro", sans-serif;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 15px;
}

.brand__info ul li::before {
	position: absolute;
	top: 5px;
	left: -26px;
	display: block;
	width: 6px;
	height: 10px;
	background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAKBAMAAABlIDIAAAAAElBMVEUAAAD/oQD/oQD/oQD/oQD/oQCmJd1/AAAABnRSTlMA3x+oPAdXhUhUAAAAIElEQVQI12NgZWBgcFRgYGAWAjIMwQwQBrGBFFgOpAYAI34Bw0HsqRYAAAAASUVORK5CYII=');
	content: "";
}

.brand__info ol li::before {
	position: absolute;
	top: -3px;
	left: -40px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	background-color: #ffa000;
	content: counter(my-counter);
	counter-increment: my-counter;
	font-family: "Myriad Pro", sans-serif;
	color: white;
	font-size: 14px;
	line-height: normal;
}

.brand__info h1 {
	color: #ffa000;
	font-family: "Myriad Pro", sans-serif;
	font-size: 32px;
	font-weight: 600;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.brand__info h2 {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 22px;
	font-weight: 600;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.brand__info h3 {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 20px;
	font-weight: 600;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.brand__info h4 {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 18px;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 1px;
}

.brand__info img {
	display: block;
	float: left;
	max-width: 600px;
	/* height: 420px; */
	height: auto;
	padding-right: 40px;
	padding-bottom: 20px;
}

@media (max-width: 991px) {
	.brand__info img {
		float: none;
		padding-right: 0;
		margin-left: auto;
		margin-right: auto;
	}

	.brand__info {
	padding-left: 30px;
	padding-right: 30px;
}
}

@media (max-width: 767px) {
	.brand__info {
		padding-top: 0;
		margin-top: 80px;
		box-shadow: none;
	}

	.brand__info img {
		max-width: calc(100% - 40px);
		object-fit: cover;
	}
}

@media (max-width: 479px) {
	.brand__info {
		padding-left: 0;
		padding-right: 0;
		padding-top: 0;
	}

	.brand__info img {
		max-width: 100%;
		padding-left: 0;
		object-fit: cover;
	}

	.brand__info p,
	.brand__info h1,
	.brand__info h2,
	.brand__info h3,
	.brand__info h4,
	.brand__info ul,
	.brand__info ol {
		padding-left: 15px;
		padding-right: 15px;
	}

	.brand__info h1,
	.brand__info h2,
	.brand__info h3,
	.brand__info h4 {
		text-align: center;
	}
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 767px) {
	.brand__info {
		margin-top: 60px;
	}

	.brand__info img {
		height: auto;
	}
}

@media (max-width: 767px) {
	_:-ms-lang(x), _:-webkit-full-screen, .brand__info {
		margin-top: 60px;
	}
}

@media (max-width: 767px) {
	_:-ms-lang(x), _:-webkit-full-screen, .brand__info img {
		height: auto;
	}
}