.production-info {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 50px;
}

.production-text {
	flex-basis: calc(100% - 510px);
}

.production-text p {
	color: #444;
	font-family: "Myriad Pro", sans-serif;
	font-size: 14px;
	line-height: 31px;
}

.production-text ol,
.production-text ul {
	list-style: none;
}


.production-text ol {
	counter-reset: my-counter;
}

.production-text li {
	position: relative;
	color: #444;
	font-family: "Myriad Pro", sans-serif;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 15px;
}

.production-text ul li::before {
	position: absolute;
	top: 5px;
	left: -26px;
	display: block;
	width: 6px;
	height: 10px;
	background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAKBAMAAABlIDIAAAAAElBMVEUAAAD/oQD/oQD/oQD/oQD/oQCmJd1/AAAABnRSTlMA3x+oPAdXhUhUAAAAIElEQVQI12NgZWBgcFRgYGAWAjIMwQwQBrGBFFgOpAYAI34Bw0HsqRYAAAAASUVORK5CYII=');
	content: "";
}

.production-text ol li::before {
	position: absolute;
	top: -3px;
	left: -40px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	background-color: #ffa000;
	content: counter(my-counter);
	counter-increment: my-counter;
	font-family: "Myriad Pro", sans-serif;
	color: white;
	font-size: 14px;
	line-height: normal;
}

.production-text h1 {
	color: #ffa000;
	font-family: "Myriad Pro", sans-serif;
	font-size: 32px;
	font-weight: 600;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.production-text h2 {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 22px;
	font-weight: 600;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.production-text h3 {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 20px;
	font-weight: 600;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.production-text h4 {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 18px;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 1px;
}

.production-photo {
	width: 460px;
	max-width: 100%;
	margin-top: 10px;
}

.production-images__wrapper {
	position: relative;
}

.production-images__item {
	background-color: white;
}

.production-photo img {
	width: 100%;
	height: auto;
	border: 9px solid #fafafa;
	box-shadow: 2.5px 4.3px 5px rgba(220, 220, 220, 0.75);
}

.production-images__item p {
	margin-top: 20px;
	color: #444;
	font-family: "Myriad Pro", sans-serif;
	font-size: 18px;
	font-style: italic;
	margin-bottom: 0;
}

.production-control {
	display: flex;
	align-items: center;
	position: absolute;
	bottom: -15px;
	right: 0;
	background-color: #FFF;
}

.production-img--left,
.production-img--right {
	background-color: #f4f2f2;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
	border-radius: 9px;
	transition: background-color .3s;
	margin-right: 10px;
	cursor: pointer;
}

.production-img--left:hover,
.production-img--right:hover {
	background-color: #FFA000;
}

.production-img--left .pageFill,
.production-img--right .pageFill{
	fill: black;
	transition: fill .3s;
}

.production-img--left:hover .pageFill,
.production-img--right:hover .pageFill{
	fill: white;
}

.production-img--right {
	margin-left: 10px;
	margin-right: 0;
}

.production-img--right svg {
	transform: rotate(180deg);
}

.production__current-item,
.production__strip-item,
.production__total-items {
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 18px;
	font-weight: 400;
	text-transform: uppercase;
}

.production-info .production-button {
	margin-bottom: 0;
}

@media (width <= 1199px) {
	.production-info {
		justify-content: center;
		flex-wrap: wrap;
	}

	.production-text {
		order: 1;
		flex-basis: 100%;
	}

	.production-photo {
		flex-basis: 100%;
		margin-bottom: 50px;
/* 		max-width: 600px;
		margin-left: auto;
		margin-right: auto; */
	}

	.production-images__wrapper {
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (width <= 991px) {
	.production-photo {
		flex-basis: 100%;
	}
}

@media (width <= 767px) {
	.production-photo {
		width: 100%;
	}
}

@media (width <= 479px) {
	.production-control {
		display: none;
	}

	.production-text h1,
	.production-text h2,
	.production-text h3,
	.production-text h4 {
		text-align: center;
	}
}

/* @media (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 991px) {
		.production-photo {
		max-width: none;
	}

	.production-images__wrapper {
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
	}
} */

@media (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 1199px) {
		.category--item img {
		height: auto;
	}
}