.production-special-offer {
	display: flex;
	justify-content: center;
	border: 1px dashed #ffa000;
	padding-top: 45px;
	padding-bottom: 50px;
	padding-right: 115px;
	padding-left: 115px;
	margin-top: 0;
	margin-bottom: 45px;
}

.special-offer--icon {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-basis: 40px;
}

.special-offer--text {
	flex-basis: calc(100% - 100px);
	margin-left: 60px;
	margin-top: 10px;
}

.special-offer--text h3 {
	margin-top: 0;
	margin-bottom: 20px;
	color: #222;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 900;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.special-offer--text p {
	margin-bottom: 0;
	color: #222;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 28px;
	letter-spacing: 2px;
	}

	@media (width <= 1199px) {
		.production-special-offer {
			padding-top: 40px;
			padding-bottom: 40px;
			padding-right: 50px;
			padding-left: 50px;
		}
	}

	@media (width <= 991px) {
		.production-special-offer {
			padding-left: 80px;
		}
	}

	@media (width <= 767px) {
		.production-special-offer {
			flex-wrap: wrap;
			padding-left: 50px;
		}

		.special-offer--icon {
			flex-basis: 100%;
			text-align: center;
		}

		.special-offer--text {
			flex-basis: 100%;
			margin-top: 15px;
			margin-left: 0;
			text-align: center;
		}

		.special-offer--text h3 {
			line-height: 1.4;
		}

		.special-offer--text p {
			font-size: 16px;
			letter-spacing: 1px;
		}
	}

	@media (width <= 479px) {
		.production-special-offer {
			padding-top: 35px;
			padding-bottom: 35px;
			padding-right: 10px;
			padding-left: 10px;
		}

		.special-offer--text p {
			font-size: 16px;
			letter-spacing: normal;
			
		}
	}