.blog-item {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
	padding: 20px;
	background-color: white;
	box-shadow: 0 0 5px rgba(220, 220, 220, 0.75);
}

.blog-img {
	flex-basis: 275px;
}

.blog-img a {
	position: relative;
	display: block;
}

.blog-img img {
	width: 100%;
	height: 100%;
	min-height: 200px;
	object-fit: cover;
}

.blog-text {
	flex-basis: calc(100% - 320px);
	margin-left: 20px;
}

.blog-text h2 {
	margin-top: 0;
	margin-bottom: 5px;
}

.blog-text h2 a {
	text-decoration: none;
	color: #222;
	font-family: "Myriad Pro", sans-serif;
	font-size: 32px;
	font-weight: 600;
	line-height: 1;
	letter-spacing: 2px;
}

.blog-text a,
.blog-img img {
	@apply --transitionOpacityStart;
}

.blog-text a:hover,
.blog-img img:hover {
	@apply --transitionOpacityEnd;
}

.blog-img a::after {
	position: absolute;
	content: "";
	width: 80px;
	height: 80px;
	border-radius: 50%;
	top: calc(50% - 40px);
	left: calc(50% - 40px);
	background-color: rgba(0,0,0,.2);
	pointer-events: none;
	opacity: 0;
	will-change: opacity;
	transition: opacity .3s;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZGF0YS1uYW1lPSJDYXBhIDEiIHZpZXdCb3g9IjAgMCAyNCAyNCI+ICA8ZGVmcz4gICAgPHN0eWxlPiAgICAgIC5jbHMtMSB7IGZpbGw6ICNmZmY7IH0gICAgPC9zdHlsZT4gIDwvZGVmcz4gIDxwYXRoIGQ9Ik0yMi4zNCAxLjY2YTUuNjggNS42OCAwIDAgMC04IDBsLTQgNGEuODcuODcgMCAxIDAgMS4yMyAxLjIzbDQtNGEzLjkzIDMuOTMgMCAwIDEgNS41NiA1LjU2bC01LjI3IDUuMjVhMy45NCAzLjk0IDAgMCAxLTUuNTYgMCAuODcuODcgMCAxIDAtMS4yMyAxLjIzIDUuNjggNS42OCAwIDAgMCA4IDBsNS4yNS01LjI1YTUuNjkgNS42OSAwIDAgMCAuMDItOC4wMnoiIGNsYXNzPSJjbHMtMSIvPiAgPHBhdGggZD0iTTExLjg1IDE3LjcxbC0zLjQgMy40YTMuOTMgMy45MyAwIDAgMS01LjU2LTUuNTZsNC45NC00Ljk0YTMuOTQgMy45NCAwIDAgMSA1LjU2IDAgLjg3Ljg3IDAgMSAwIDEuMjMtMS4yMyA1LjY4IDUuNjggMCAwIDAtOCAwbC00Ljk2IDQuOTRhNS42OCA1LjY4IDAgMCAwIDggOGwzLjQtMy40YS44Ny44NyAwIDAgMC0xLjIxLTEuMjF6IiBjbGFzcz0iY2xzLTEiLz48L3N2Zz4=);
	background-repeat: no-repeat;
	background-position: center;
}

.blog-img a:hover::after {
	opacity: 1;
}

.blog-text > span {
	display: inline-block;
	color: #ffa000;
	font-family: "Myriad Pro", sans-serif;
	font-size: 14px;
	font-weight: 600;
	line-height: 1;
	letter-spacing: 1px;
	margin-bottom: 15px;
}

.blog-text p {
	color: #444;
	font-family: "Myriad Pro", sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 31px;
}

.blog-text .read-more-link svg {
	transform: rotate(180deg);
	margin-left: 10px;
	fill: #ffa000;
}

.blog-text .read-more-link span {
	display: inline-block;
	color: #ffa000;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 900;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 1px;
}

@media (width <= 767px) {
	.blog-item {
		flex-wrap: wrap;
		padding: 10px 10px 20px;
	}

	.blog-img {
		flex-basis: 100%;
		margin-bottom: 20px;
	}

	.blog-img img {
		display: block;
    max-width: 445px;
    max-height: 250px;
    margin-left: auto;
    margin-right: auto;
	}

	.blog-text {
		flex-basis: 100%;
	}
}

@media (width <= 479px) {
	.blog-text h2 a {
		font-size: 28px;
		letter-spacing: normal;
	}

	.blog-text {
		margin-left: 0;
	}
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 991px) {
	.blog-img img {
		height: auto;
	}
}