.carousel-caption {
	text-align: left;
	right: 0;
	left: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	margin-left: 90px;
}

.carousel-caption h2 {
	font-family: "Myriad Pro", sans-serif;
	font-size: 48px;
	font-weight: 700;
	line-height: 52px;
	text-transform: uppercase;
	color: white;
}

.carousel-caption h2 span {
	display: block;
}

.carousel-caption h2 span:last-of-type {
	color: #ffa000;
}

.carousel-caption p {
	margin-bottom: 35px;
	flex-basis: 500px;
	width: 500px;
	color: white;
	font-family: "Myriad Pro", sans-serif;;
	font-size: 14px;
	line-height: 26px;
	letter-spacing: 1px;
}

.item h2,
.item p,
.item a {
	opacity: 0;
	transform: translateY(20px);
	transition: opacity 1s, transform 1s;
}

.item p {
	transition-delay: .3s;
}

.item a {
	transition-delay: .6s;
}

.item.active h2,
.item.active p,
.item.active a {
	opacity: 1;
	transform: translateY(0);
}

.carousel-caption a {
	display: inline-block;
	text-align: center;
	height: 46px;
	letter-spacing: 1px;
	flex-basis: 235px;
	width: 235px;
	color: white;
	font-family: 'Roboto', sans-serif;
	font-size: 11px;
	font-weight: 900;
	line-height: 46px;
	text-transform: uppercase;
	border: 1px solid white;
	border-radius: 23px;
	text-decoration: none;
	transition: background-color .3s, border-color .3s, opacity 1s .6s, transform 1s .6s;
}

.carousel-caption a:hover {
	background-color: #ffa000;
	border-color: #ffa000;
}

.carousel-inner>.item>img {
	min-height: 500px;
	object-fit: cover;
}

@media (width >= 1200px) {
	.carousel-caption {
		margin-left: 110px;
	}
}

@media (max-width: 1199px) {
	.carousel-inner>.item>img {
		min-height: 500px;
		object-fit: cover;
		object-position: 70%;
	}

	.carousel-caption h2 {
		font-size: 44px;
		margin-top: 0;
		margin-bottom: 0;
		max-width: 450px;
	}

	.carousel-caption p  {
		margin-bottom: 20px;
		max-width: 450px;
	}
}

@media (max-width: 991px) {
	.carousel-inner>.item>img {
		object-position: 60%;
	}

	.carousel-caption h2 {
		font-size: 44px;
		margin-bottom: 10px;		
	}

	.carousel-caption p  {
		margin-bottom: 30px;
	}
}

@media (max-width: 767px) {
	.banner__wrapper {
		margin-top: 20px;
	}
	
	.carousel-inner>.item>img {
		object-position: 60%;
	}

	.carousel-caption {
		left: 30px;
		right: auto;
		/* margin-left: 70px; */
	}

	.carousel-caption h2 {
		font-size: 36px;
		line-height: 1.2;	
	}
}

@media (max-width: 600px) {
	.carousel-caption {
		margin-top: -100px;
		margin-left: 40px;
	}

	.carousel-caption h2 {
		font-size: 32px;
		line-height: 1.2;	
	}
}

@media (max-width: 479px) {
	.carousel-inner>.item>img {
		object-position: 65%;
	}

	.carousel-caption {
		left: -15px;
		margin-top: -100px;
		margin-left: -20px;
		margin-right: 5px;
	}
	
	.carousel-caption h2 {
		font-size: 24px;
		line-height: 1.4;
		margin-top: 40px;
	}

	.carousel-caption p {
		max-width: 280px;
		margin-top: 25px;
	}
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.carousel-inner>.item>img {
		min-height: 0;
	}
}

_:-ms-lang(x), _:-webkit-full-screen, .carousel-inner>.item>img {
	min-height: 0!important;
}

@media (max-width: 1199px) {
	_:-ms-lang(x), _:-webkit-full-screen, .carousel-caption h2 {
		font-size: 36px;
		line-height: 1;
		margin-bottom: 10px;
	}
}

@media (max-width: 1199px) {
	_:-ms-lang(x), _:-webkit-full-screen, .carousel-caption p {
		line-height: 1.2;
	}
}

@media (max-width: 992px) {
	_:-ms-lang(x), _:-webkit-full-screen, .carousel-caption h2 {
		margin-bottom: 30px;
		font-size: 30px;
	}
}

@media (max-width: 992px) {
	_:-ms-lang(x), _:-webkit-full-screen, .carousel-caption p {
		display: none;
	}
}

@media (max-width: 767px) {
	_:-ms-lang(x), _:-webkit-full-screen, .carousel-caption a {
		margin-top: -10px;
	}
}

@media (max-width: 600px) {
	_:-ms-lang(x), _:-webkit-full-screen, .carousel-caption h2 {
		display: none;
	}
}

@media (max-width: 600px) {
	_:-ms-lang(x), _:-webkit-full-screen, .carousel-caption a {
		display: none;
	}
}

@media (-ms-accelerator:true) and (max-width: 1199px){
	.carousel-caption h2 {
		font-size: 36px;
		line-height: 1;
		margin-bottom: 10px;
	}

	.carousel-caption p {
		line-height: 1.2;
	}
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 1199px) {
	.carousel-caption h2 {
		font-size: 36px;
		line-height: 1;
		margin-bottom: 10px;
	}

	.carousel-caption p {
		line-height: 1.2;
	}
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 991px) {
	.carousel-caption h2 {
		margin-bottom: 30px;
	}

	.carousel-caption p {
		display: none;
	}
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 767px) {
	.carousel-inner>.item>img {
		/* min-height: 200px; */
		width: auto;
		height: auto;
	}

	.carousel-caption h2 {
		margin-bottom: 40px;
		font-size: 30px;
	}
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: 600px) {
	.carousel-caption {
		margin-top: 0;
	}

	.carousel-caption h2 {
		display: none;
	}

	.carousel-caption a {
		display: none;
	}
}

