.contacts__wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 15px;
	margin-bottom: 70px;
	background-color: white;
	box-shadow: 0 0 5px rgba(220, 220, 220, 0.75);
}


@media (width <= 1199px) {
	.contacts__wrapper {
		justify-content: center;
	}	
}

@media (width <= 991px) {
	.contacts__wrapper {
		margin-bottom: 0;
		box-shadow: none;
	}
}

@media (width <= 767px) {
	.contacts__wrapper {
		margin-top: 0;
	}
}